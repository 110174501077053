<template>
  <div class="membership">
    <div class="el_card">
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in orderType"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === typeIndex ? 'active' : ''"
        >
          {{ item.code }}
        </div>
      </div>
      <div class="parameter">
        <!-- <div class="parameter_form">
          <el-form>
            <el-form-item label="入会方式：" v-if="typeIndex == '0'">
              <el-radio-group v-model="queryInfo.membershipMethod" @change="search">
                <el-radio-button label="">全部 </el-radio-button>
                <el-radio-button label="入会附件">入会附件 </el-radio-button>
                <el-radio-button label="填写表单">填写表单 </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-else label="审核状态：">
              <el-radio-group v-model="queryInfo.auditStatus" @change="search">
                <el-radio-button label="0">全部 </el-radio-button>
                <el-radio-button label="1">审核通过 </el-radio-button>
                <el-radio-button label="2">审核不通过 </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div> -->
        <!-- 待审核 -->
        <div v-if="typeIndex == '0'" class="parameter_item">
          <div class="item">
            <div class="search_item_label">入会方式：</div>
            <el-radio-group v-model="queryInfo.membershipMethod" @change="search">
              <el-radio-button label="">全部 </el-radio-button>
              <el-radio-button label="入会附件">入会附件 </el-radio-button>
              <el-radio-button label="填写表单">填写表单 </el-radio-button>
            </el-radio-group>
          </div>
          <!-- <div class="item">
              <div class="search_item_label">支付状态：</div>
              <el-radio-group v-model="queryInfo.membershipMethod" @change="search">
                <el-radio-button label="">全部 </el-radio-button>
                <el-radio-button label="入会附件">已支付 </el-radio-button>
                <el-radio-button label="填写表单">未支付 </el-radio-button>
              </el-radio-group>
            </div> -->
        </div>
        <!-- 已审核 -->
        <div v-else class="parameter_item">
          <div class="item">
            <el-button class="btn" @click="exportUserExcel">批量导出</el-button>
            <div class="search_item_label">审核状态：</div>
            <el-radio-group v-model="queryInfo.auditStatus" @change="search">
              <el-radio-button label="0">全部 </el-radio-button>
              <el-radio-button label="1">审核通过 </el-radio-button>
              <el-radio-button label="2">审核不通过 </el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="parameter_input">
          <el-input
            placeholder="请输入用户昵称，用户手机号"
            v-model="queryInfo.realNameOrPhone"
            class="input-with-select"
          >
            <el-button class="search_btn" slot="append" @click="search()">搜索</el-button>
          </el-input>
        </div>
      </div>
      <div class="table_box">
        <el-table
          v-loading="loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
          @sort-change="sortChanged"
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center" :reserve-selection="true" />
          <el-table-column width="100" label="编号" align="center" :key="1">
            <template v-slot="{ row }">
              <span>{{ 'CUC' + row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="姓名" prop="realName" :key="2"></el-table-column>
          <el-table-column
            width="120"
            align="center"
            label="手机号"
            prop="phone"
            :key="3"
          ></el-table-column>
          <el-table-column
            align="center"
            label="申请职务"
            prop="applyJob"
            :key="4"
          ></el-table-column>
          <el-table-column
            align="center"
            label="企业名称"
            prop="companyName"
            :key="5"
          ></el-table-column>
          <el-table-column
            align="center"
            v-if="typeIndex == '1'"
            label="审核状态"
            width="150"
            prop="refusalReasons"
            :key="6"
          >
            <template v-slot="{ row }">
              <div class="table_pass" v-if="row.membershipStatus == '1'">审核通过</div>
              <div class="table_nopass" v-if="row.membershipStatus == '2'">
                <span>审核不通过</span>
                <el-popover title="理由" width="200" trigger="hover" :content="row.refusalReasons">
                  <i slot="reference" class="el-icon-warning" style="color: #ff7878"></i>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="typeIndex == '0'"
            align="center"
            label="入会方式"
            prop="membershipMethod"
            :key="6"
          ></el-table-column>
          <el-table-column
            align="center"
            label="关联商会"
            prop="chamberCommerceName"
            :key="7"
          ></el-table-column>
          <el-table-column
            align="center"
            label="关联秘书长"
            prop="secretaryGeneralName"
            :key="8"
          ></el-table-column>
          <el-table-column
            min-width="100"
            align="center"
            label="入会附件"
            prop="attachmentAddress"
            :key="9"
          >
            <template v-slot="{ row }">
              <el-tooltip effect="dark" popper-class="tool_tip" placement="top">
                <div slot="content">
                  {{ row.attachmentAddress }}
                </div>
                <div class="address_box" @click="clickUrl(row)">
                  {{ row.attachmentAddress }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            min-width="150"
            align="center"
            label="申请时间"
            prop="createTime"
            v-if="typeIndex == '0'"
            sortable
            :key="10"
          ></el-table-column>
          <el-table-column
            min-width="150"
            align="center"
            prop="auditTime"
            sortable
            label="审核时间"
            v-if="typeIndex == '1'"
            :key="11"
          ></el-table-column>
          <el-table-column
            align="center"
            label="审核人"
            prop="userName"
            v-if="typeIndex == '1'"
            :key="12"
          ></el-table-column>
          <el-table-column align="center" label="操作" :min-width="getLabelWidth()" :key="13">
            <template v-slot="{ row }">
              <div class="table_item">
                <div class="pass btn_box" @click="passClick(row, '1')" v-if="typeIndex == '0'">
                  通过
                </div>
                <div class="no_pass btn_box" @click="passClick(row, '2')" v-if="typeIndex == '0'">
                  不通过
                </div>
                <div
                  class="edit"
                  v-if="row.membershipMethod !== '入会附件'"
                  @click="editClick(row.id, typeIndex)"
                >
                  详情
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="new_page" v-if="listData.length">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog title="不通过" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="理由" prop="refusalReasons">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入不通过理由"
              v-model="ruleForm.refusalReasons"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const defaultQueryInfo = Object.freeze({
  sortDirection: 'desc', // 排序方向（默认：desc；asc）
  sortType: 'createTime', // 排序字段名称
  pageNum: 1, //	页数
  pageSize: 10, //大小
  auditStatus: 0, //审核状态（0：全部、1：审核通过、2：审核不通过）
  membershipMethod: '', //	入会方式
  membershipStatus: '', //会员状态（0：待审核、1：已审核）
  realNameOrPhone: '' //真实姓名/手机号/关联商会
})
const defaultRuleForm = Object.freeze({
  id: '',
  membershipStatus: '',
  phone: '',
  refusalReasons: ''
})
import { membershipList, membershipEdit, downLoadFile, exportExcel } from '@/api/review'
export default {
  name: 'Membership',
  data() {
    return {
      ids: [], // 选中的用户列表
      rules: {
        refusalReasons: [{ required: true, message: '请输入活动名称', trigger: 'blur' }]
      },
      ruleForm: { ...defaultRuleForm },
      dialogVisible: false, //填写拒绝理由的弹窗
      // 遮罩层
      loading: false,
      queryInfo: { ...defaultQueryInfo }, //参数
      total: 0,
      listData: [],
      typeIndex: 0,
      orderType: [
        {
          id: 0,
          code: '待审核'
        },
        {
          id: 1,
          code: '已审核'
        }
      ]
    }
  },
  created() {
    this.queryInfo.membershipStatus = this.typeIndex
    this.search() //获取表格数据
  },
  methods: {
    // 计算操作列宽度
    getLabelWidth() {
      let val = ''
      if (this.queryInfo.membershipStatus == '0') {
        val = '140'
      } else {
        val = '60'
      }
      return val
    },
    //保存首页勾选数据
    getRowKey(row) {
      return row.id
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    // 导出已审核用户名单
    async exportUserExcel() {
      if (this.ids.length > 0) {
        this.loading = true
        const query = {
          ids: this.ids
        }
        const res = await exportExcel(query)
        if (res.data) {
          this.loading = false
          let blob = new Blob([res.data])
          let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          console.log(href)
          a.href = href // 指定下载'链接
          let nowdata = new Date()
          let year = nowdata.getFullYear()
          let month = nowdata.getMonth() + 1
          let date = nowdata.getDate()
          let nowtime = year + '.'
          if (month < 10) {
            nowtime += '0'
          }
          nowtime += month + '.'
          if (date < 10) {
            nowtime += '0'
          }
          nowtime += date
          a.download = `CUC商盟成员名单${nowtime}.xlsx` //指定下载文件名
          // a.download = "test.png";
          a.click() //触发下载
          window.URL.revokeObjectURL(a.href) //释放URL对象
          if (document.body.contains(a)) {
            document.body.removeChild(a) //释放标签
          }
          this.search()
          // this.isIndeterminate = false
          // this.checkAll = false
        } else {
          this.loading = false
          this.$message.error('导出失败!')
        }
      } else {
        this.$message.warning('请先选择需要导出的用户~')
      }
    },

    //点击详情跳转页面
    editClick(id, type) {
      this.$router.push({
        path: '/membership/review/detailed',
        query: {
          id,
          type
        }
      })
    },
    //不通过点击确定
    submitClick() {
      const query = {
        id: this.ruleForm.id,
        membershipStatus: '2',
        phone: this.ruleForm.phone,
        refusalReasons: this.ruleForm.refusalReasons
      }
      membershipEdit(query).then((res) => {
        if (res.data.resultCode === 200) {
          this.$message.success('审核不通过。')
          this.search()
          this.ruleForm = { ...defaultRuleForm }
          this.dialogVisible = false
        } else {
          this.$message.error('网络繁忙，请稍候重试。')
        }
      })
    },
    //关闭不通过的弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.ruleForm = { ...defaultRuleForm }
          done()
        })
        .catch((_) => {})
    },
    //点击通过不通过
    passClick(row, type) {
      //type = 1通过  type = 2 不通过
      if (type == '1') {
        //点击通过
        const query = {
          id: row.id,
          membershipStatus: '1',
          phone: row.phone,
          refusalReasons: ''
        }
        membershipEdit(query).then((res) => {
          if (res.data.resultCode === 200) {
            this.$message.success('审核通过。')
            this.search()
          } else {
            this.$message.error('网络繁忙，请稍候重试。')
          }
        })
      } else {
        this.ruleForm = { ...row }
        this.dialogVisible = true
      }
    },
    //点击审核状态tab切换
    storeClick(id) {
      // id为number类型
      this.queryInfo.membershipStatus = id + ''
      this.typeIndex = id
      if (id === 1) {
        this.queryInfo.sortType = 'auditTime'
      } else {
        this.queryInfo.sortType = 'createTime'
      }
      this.search()
    },
    //获取表格数据
    async search() {
      this.loading = true
      const res = await membershipList(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      } else {
        this.loading = false
      }
    },
    // 排序前重置排序字段
    resetSort() {
      // this.queryInfo.sortType = 'update_time'
      this.queryInfo.sortDirection = 'desc'
    },
    //排序
    sortChanged(val) {
      //val里面返回prop(排序字段)和order(排序方向)
      this.queryInfo.sortType = val.prop
      if (val.order === 'ascending') {
        this.queryInfo.sortDirection = 'asc'
      } else {
        // 当表格order为'desc'或null时
        this.resetSort()
      }
      this.search()
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 打开附件地址
    async clickUrl(row) {
      // `${row.companyName}-企业信息表.docx`
      const name = 'CUC商盟企业信息表.docx'
      const query = {
        urlStr: row.attachmentAddress,
        name
      }
      console.log(query)
      const res = await downLoadFile(query)
      if (res.data) {
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        console.log(href)
        a.download = name //指定下载文件名
        // a.download = 'test.png'
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      }
    }
  }
}
</script>

<style lang="less">
.tool_tip {
  max-width: 400px;
  max-height: 400px;
  overflow-y: auto;
  line-height: 20px;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
</style>
<style lang="less" scoped>
.el_card {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  .table_box {
    width: 100%;
    height: calc(100% - 160px);
    overflow-y: auto;
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }
  }
}
.address_box {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  color: #4e93fb;
}
.membership {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .parameter {
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .parameter_item {
      flex: 1;
      display: flex;
      .item {
        display: flex;
        line-height: 40px;
        .btn {
          margin-right: 20px;
        }
        .search_item_label {
          font-size: 14px;
          color: #7c7f8e;
        }
      }
    }

    .parameter_input {
      width: 400px;
      margin-left: 20px;

      .search_btn {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }

  .in_table {
    .table_item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .btn_box {
        cursor: pointer;
        width: 50px;
        height: 24px;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        border-radius: 4px;
      }
      .pass {
        color: #4e93fb;
        border: 1px solid #4e93fb;
      }

      .no_pass {
        color: #fb6956;
        border: 1px solid #fb6956;
        margin-left: 10px;
      }

      .edit {
        width: fit-content;
        color: #146aff;
        font-size: 14px;
        cursor: pointer;
      }
      .no_pass + .edit {
        margin-left: 10px;
      }
    }
    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .store_table {
    width: 100%;
    overflow: hidden;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;

    .store_table_item {
      padding: 0px 20px;
      overflow: hidden;
      line-height: 48px;
      box-sizing: border-box;
      color: #1f2d3d;
      cursor: pointer;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
    }

    .active {
      border-bottom: 2px solid #4e93fb;
      color: #4e93fb;
      background-color: #fff;
    }
  }
}
.new_page {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
</style>
